import React from 'react'

const Sun = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" x="0px" y="0px">
        <g>
            <path fill="var(--primary-text-color)" d="M23,16a7,7,0,1,1-7-7A7,7,0,0,1,23,16ZM16,7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V6A1,1,0,0,0,16,7ZM8.22,9.64a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42L7.51,6.1A1,1,0,0,0,6.1,7.51ZM7,16a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H6A1,1,0,0,0,7,16Zm1.22,6.36L6.1,24.49a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l2.13-2.12a1,1,0,0,0-1.42-1.42ZM16,25a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0V26A1,1,0,0,0,16,25Zm7.78-2.64a1,1,0,0,0-1.42,1.42l2.13,2.12a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41ZM29,15H26a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2ZM23.07,9.93a1,1,0,0,0,.71-.29L25.9,7.51A1,1,0,1,0,24.49,6.1L22.36,8.22a1,1,0,0,0,0,1.42A1,1,0,0,0,23.07,9.93Z"/>
        </g>
    </svg>
)

export default Sun