import React from 'react'

export default ({ className, color = '#6247AA' }) => (
    <svg className={className ? `Logo ${className}` : 'Logo'} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 551.29 136.38">
        <defs>
            <style>
                {`.cls-1{fill:${color};}.cls-1,.cls-2{fill-rule:evenodd;}.cls-2{fill:#83e4c8;}`}
            </style>
        </defs>
        <path className="cls-1" d="M208,327.81a39.88,39.88,0,0,1,0,79.57V382.94a15.6,15.6,0,0,0,0-30.69Zm-42.47,43.9a39.91,39.91,0,0,1,36.57-43.88v24.48a15.6,15.6,0,0,0-12.37,13.27h0v17.3h12.33v24.4H189.8v15.17c0,.65,0,1.31,0,2a39.89,39.89,0,0,1-36.78,39.76V439.72a15.61,15.61,0,0,0,12.49-15.29,16.52,16.52,0,0,0-.16-2.24V407.28H153.07v-24.4h12.49Z" transform="translate(-120.35 -327.81)"/>
        <polygon className="cls-2" points="0 67.27 13.4 59.53 26.81 51.79 26.81 67.27 26.81 82.75 13.4 75.01 0 67.27"/>
        <path className="cls-1" d="M305,363.91a4.35,4.35,0,0,1,4.45-4,3.94,3.94,0,0,1,4.25,4Zm4.41-7.14a8.07,8.07,0,0,0-8.45,8.48c0,5,3.39,8.42,8.55,8.42a9.24,9.24,0,0,0,7-2.86l-2.18-2.27a6.13,6.13,0,0,1-4.47,1.87,4.63,4.63,0,0,1-4.75-3.79h12.27C317.75,360.59,315.24,356.77,309.39,356.77Zm-17.61,0c-2.74,0-4.82,1.05-6,3.32v-9.6h-4v23.06h4v-8.18c0-2.73,1.65-4.75,4.38-4.78a3.45,3.45,0,0,1,3.7,3.76v9.2h4.07V363.14C297.93,359.19,295.6,356.74,291.78,356.74Zm-29.18-5v3.69h6.77v18.06h4.2V355.46h6.77v-3.69Z" transform="translate(-120.35 -327.81)"/>
        <path className="cls-1" d="M649.2,420c-9.38,0-16-6.95-16-16.67s6.61-16.74,16-16.74,15.91,7,15.91,16.74S658.5,420,649.2,420m15.91-56.86v27.63c-3.26-6.28-9.37-9.88-17.25-9.88-12.47,0-21.18,9.13-21.18,22.28s8.71,22.44,21.27,22.44c7.87,0,13.9-3.6,17.16-9.8v9.46h6.54V363.16ZM598.19,381c-8,.09-14,3.27-17.08,10v-9.71h-6.53v44h6.53V401.68c.75-8.54,6.11-14.32,14.82-14.41,7.79,0,12.48,4.69,12.48,12.57v25.45h6.53V398.16C614.94,387.44,608.74,381,598.19,381Zm-47.66.26v21.6c0,9.55-5.36,16.25-14.49,16.41-7.62,0-12.23-4.69-12.23-12.56V381.25h-6.53v27.13c0,10.63,6.2,17.17,16.5,17.17,8-.09,13.82-3.35,16.75-10.22v10h6.53v-44Zm-45.4-12.48a12,12,0,0,1,6.7,2.26l2.67-5a16,16,0,0,0-9.46-3.09c-7.37-.09-14.15,5.19-14.15,14.74v4.94H485.2v5.1h5.69v37.6h6.45v-37.6H510v-5.1H497.34v-5.78C497.34,371.62,500.86,368.68,505.13,368.77Zm-29.41,45.55a15.13,15.13,0,0,1-6.37,1.85c-2.93,0-4.69-1.43-4.69-5.7V390.29h12.82v-8H464.66V370h-10.8v12.31H447.5l.08,8h6.28V412.4c0,9.21,5.86,13.4,13.15,13.4a21.61,21.61,0,0,0,11.22-3.27Zm-58.3-24.87C424.79,389.45,430,395,430,403s-5.19,13.56-12.56,13.56c-7.54,0-12.73-5.52-12.73-13.56S409.88,389.45,417.42,389.45Zm0-9.29c-14.15,0-23.7,9.13-23.7,22.69s9.55,22.86,23.7,22.86c14,0,23.53-9.21,23.53-22.86S431.4,380.16,417.42,380.16Zm-71.45.42,17.26,44.71h11.22l17.08-44.8h-11l-11.47,34.08-11.73-34.08Zm-19.1-.09v44.8h10.88v-44.8Zm5.44-18.84a6,6,0,1,0,5.86,6A5.72,5.72,0,0,0,332.31,361.65Zm-40.13,54.68c-7.45,0-12.64-5.52-12.64-13.56,0-7.87,5.19-13.48,12.64-13.48s12.57,5.61,12.57,13.48S299.55,416.33,292.18,416.33Zm2.43-36.17c-6.7,0-11.89,2.76-15.07,7.7v-7.37H268.65v61.05h10.89V418c3.27,4.94,8.46,7.7,15.24,7.7,12.73,0,21-9.13,21-22.61C315.8,389.37,307.26,380.16,294.61,380.16Z" transform="translate(-120.35 -327.81)"/>
    </svg>
)